:root {
  --container-height: 35vw;
  --loader-border: 0.2rem;
  --loader-size: 1rem;
  --background-color: transparent;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
  height: var(--container-height);
  padding: 1.3% 0;
}

.loader {
  border: var(--loader-border) solid #f3f3f3;
  border-top: var(--loader-border) solid #3498db;
  border-radius: 50%;
  width: var(--loader-size);
  height: var(--loader-size);
  animation: spin 1s linear infinite;
}

.loader2Container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--container-height);
  padding: 1.3% 0;
  background-color: black;
}

.loader2 {
  border: var(--loader-border) solid #f3f3f3;
  border-top: var(--loader-border) solid #3498db;
  border-radius: 50%;
  width: var(--loader-size);
  height: var(--loader-size);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1024px) {
  .loaderContainer {
    height: 100vw;
  }
}
