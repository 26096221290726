.registerationFormContainer form{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: x-large;
  }
  .heading{
    display: flex;
    align-items: center;
    font-size: 1.2em;
  }
  .registerationFormContainer input{
    width: 400px;
    height: 50px;
    background-color: rgb(233, 232, 232);
    border: none;
    margin: 10px;
    padding: 8px;
    font-size: 20px;
  }
  .registerationFormContainer label{
    margin: 10px;
  }
  .signup{
    background-color: rgb(115, 67, 238) !important;
    color: white !important;
  }
  .registerationFormContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3em;
  }
  .alreadyRegistered{
    margin-top: 1.5em;
    display: flex;
    justify-content: flex-end;
    font-size: 1.4em;
   }
  .loginBtn{
    border: none;
    color: blue;
    background-color: white;
    margin-left: 0.5em;
    font-size: 1em;
    cursor: pointer;
  }
  .loaderContainer{
    margin: 0.6rem 0 0.6rem 0.6rem;
    width: 25rem;
  }
  .signup:hover, .loaderContainer:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  @media screen and (max-width:500px) {
    .registerationFormContainer input{
      width: 300px;
      height: 30px;
      font-size: 15px;
    }
    .registerationFormContainer form{
      margin-left: 0px;
    }
    .loaderContainer{
      width: 20rem;
    }
    .signup{
      width: 20rem;
      height: 2.5rem;
      font-weight: 600;
    }
    .registerationFormContainer h1{
      font-size: 2rem;
    }
    .registerationFormContainer label,strong{
      font-size: 1.2rem;
    }
    .loginBtn{
      font-size: 1.2rem;
      padding: 0rem;
    }
  }