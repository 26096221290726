*{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    box-sizing: border-box;
}
button{
    cursor: pointer;
}
.link{
    color: black;
    text-decoration: none;
}
.addbtn{
    border:2px solid #3669C9;
    padding: 1em;
    color: white;
    background-color: #3669C9;
    margin-top: 2em;
    width: 14em;
    font-size: 0.7em;
    margin-right:1em;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
}
.addbtn:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.removebtn{
    border:2px solid #3669C9;
    padding: 1em;
    color: #3669C9;
    background-color: white;
    margin-top: 2em;
    width: 14em;
    font-size: 0.7em;
    margin-right:1em;
    cursor: pointer;
    font-weight: bold;
    border-radius: 5px;
}
.removebtn:hover{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}


@media screen and (max-width: 910px) {
    .addbtn,.removebtn{
        width: 9rem;
        margin-top: 1rem;
        margin-right: 0rem;
        padding: 0.6rem 1rem;
    }
}