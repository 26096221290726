.nav{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-size: 1.5em;
    padding: 0.6rem 0.2rem;
    background-color: #3669C9;
    z-index: 1;
    border-bottom: 2px solid white;
}
.allProductsContainer{
    text-align: right;
}
.sort{
    padding: 0.25rem 0.1rem;
    margin: 2rem 5rem 0 0;
    font-size: 1.02rem;
    border: none;
    background-color: #606060;
    border-radius: 5px;
    color: white;
}
.empty{
    display: flex;
    width: 100%;
    height: 50vw;
    justify-content: center;
    align-items: center;
}
.searchContainer{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}
.formContainer{
    position: absolute;
    width: 40%;
    top: 5rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}
.search{
    flex-grow: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.searchBar{
    width: 100%;
    font-size: 1rem;
    height: 2.3rem;
    border-radius: 5px;
    background-color: rgb(233, 232, 232);
}
.searchBtn{
  font-size: 1.01rem;
  flex-grow: 1;
  margin: 0 0 0 1rem;
  padding: 0.425rem;
  max-width: 7rem;
}
.suggestionsContainer{
  display: flex;
  width: inherit;
  flex-direction: column;
  align-items: flex-start;
  top: 2rem;
  margin-top: -0.4rem;
  z-index: 1;
  box-sizing: border-box;
  overflow-y: auto;
}
.suggestion{
    font-size: 1rem;
    font-weight: 500;
    background-color: rgb(233, 232, 232);
    padding: 0.3rem;
    width: 100%;
    border-left: 2px solid #3669C9;
    border-right: 2px solid#3669C9;
}
.suggestion:last-child{
    border-bottom: 2px solid #3669C9;
}
.displayFooter{
    display: block;
}
.displayBottomNav{
    display: none;
}
.logoContainer{
    height: 40vw;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bandageLogo{
    width: 8rem;
    animation: growImage 1.5s forwards;
}
@keyframes growImage {
    from {
      width: 0;
      height: 0;
      opacity: 0;
    }
    to {
      width: 8rem;
      height: 10rem;
      opacity: 1;
    }
}
.product{
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    padding: 2em;
}
.product:hover{
    box-shadow: 0 0 0 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    transition: 0.8s;
}
.loginbtn{
    border: none;
    background-color: transparent;
    font-size: 0.8em;
    color: white;
    margin-right: 3em;
    margin-bottom: 0.2em;
}
.icons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 4em;
    padding-left: 2em;
}
.icons i{
    color: white;
}
.image{
    height: 1.4em;
    width: 1.4em;
    border-radius: 50%;
    margin-top: 0.3em;
    cursor: pointer;
    object-fit: cover;
}

footer{
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    height: 25em;
    color: white;
    background-color: #171934;
    padding-left: 0.59em;
    padding-right: 0.59em;
}
.footerHeading{
    padding-left: 8em;
    padding-right: 8em;
    padding-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.5em;
}
.socialM{
    width: 10em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footerLogo{
    margin-right: 2em;
    font-family: "Madimi One", sans-serif;
}
.hrLine{
    max-width: 74em;
    min-width: 70vw;
    margin-top: 2em;
}
.table{
    margin-left: 7em;
    margin-right: 7em;
    display: flex;
    justify-content: center;
    font-size: 1em;
}
th,td{
    padding: 1em;
    text-align: left;
}
.searchIcon{
    margin-right: 2.5rem;
    font-size: 1.7rem;
    cursor: pointer;
    color: white;
    margin-left: auto;
    margin-right: -0.4rem;
}
.orderIcon{
    margin: 0.35rem 0rem 0rem 3rem;
    font-size: 1.7rem;
    color: white;
}
.cartIcon{
    margin-left: 1.5em;
    font-size: 1.3em;
    margin-top: 0.1em;
    cursor: pointer;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    position: relative;
}
.cartIcon i, .idLogo i, .notLogged i {
    color: white;
}
.idLogo{
    cursor: pointer;
}
.notLogged{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.2em;
    width: 2em;
    font-size: 1.7rem;
}
.logo{
    font-size: 2rem;
    margin-left: 6rem;
    cursor: pointer;
    margin-right: 3rem;
    color: white;
    font-family: "Madimi One", sans-serif;
}
.cartIcon .number{
    font-size: 0.8rem;
    font-weight: bold;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    border-radius: 50%;
    height: 1.2rem;
    width: 1.2rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: white;
    box-shadow: 0 0 8px 3px #B8B8B8;
}

.mobileBottomNaveContainer{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #3669C9;
    border-top: 2px solid white;
    z-index: 1;
    padding: 0.25rem 1.2rem;
    font-size: 2.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    box-sizing: border-box;
}

.bottomHomeIcon{
    color: white;
    font-size: 1.6rem;
}

.bottomIcon{
    margin-left: 3rem;
    color: white;
    font-size: 1.9rem;
}

.bottomImage{
    height: 1.9rem;
    width: 1.9rem;
    border-radius: 50%;
    margin-left: 3rem;
    margin-top: 0.55rem;
    align-self: center;
    object-fit: cover;
}

.bottomLoginLink{
    display: flex;
    align-items: center;
    text-decoration: none;
}

.bottomLoginbtn{
    border: none;
    background-color: transparent;
    color: white;
    font-size: 1rem;
}

.noMatchContainer{
    width: 100%;
    height: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.noMatchContainer img{
    width: 40rem;
    height: 20rem;
    margin-top: 4rem;
}

@media screen and (max-width: 910px) {
    .displayFooter{
        display: none;
    }
    .displayBottomNav{
        display: block;
    }
    .icons{
        display: none !important;
    }
    .nav{
        padding: 0.4rem 0rem;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
    }
    .logo {
        margin: 0 0 0 1.5rem;
    } 
    .searchIcon {
        margin: 0 1.5rem 0 auto;
    }
    .sort{
        margin: 1.5rem 1.5rem 0 0;
        width: 5rem;
        font-size: 1rem;
    }
    .formContainer{
        width: 90%;
    }
    .searchBtn{
        max-width: 5rem;
    }
    .cartIcon{
        margin: 0.25rem 0 0 3rem;
        font-size: 2rem;
    }
    .cartIcon i{
        color: white;
    }
    .cartIcon .number{
        width: 1rem;
        height: 1rem;
        top: -0.2rem;
    }
    .logoContainer{
        height: 130vw;
        width: 100vw;
        transform: scale(0.8);
    }
    .empty{
        height: 100vw;
    }
}

@media screen and (max-width: 940px) {
    .orderIcon{
        margin: 0.49rem 0rem 0rem 3rem;
        font-size: 1.6rem;
        transform: scale(1.1);
    }
}

@media screen and (max-width: 1180px) {
    .productsContainer{
        max-width: 82em;
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: center;
        align-items: center;
    }
  }

@media screen and (max-width: 1024px) {
    .productsContainer{
        padding: 0rem 0rem 4rem 0rem;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
        align-items: center;
    }
    .noMatchContainer{
        height: 100vw;
    }
    .noMatchContainer h1{
       font-size: 1.5rem;
    }
    .noMatchContainer img{
        width: 20rem;
        height: 10rem;
        margin-top: 2rem;
    }
  }

  
@media screen and (min-width: 1180px) {
    .productsContainer{
        display: grid;
        align-items: center;
        grid-template-columns: auto auto auto auto;
        justify-content: center;
    }
  }
